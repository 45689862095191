import SVGIcon from '~/components/ui/Icons/SVGIcon';
import { Button } from '~/components/common/buttons/Button';
import mixpanelTrack from '~/hooks/useMixPanel';
import { LinkText } from '~/components/common/buttons/LinkText';
import { useFetcher, useLocation, useNavigate } from '@remix-run/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useUser } from '~/modules/useUser';
import { useLanguageParam } from '~/config/i18n';
import { checkTokenValidity } from '~/components/utils/verification';

type TProps = {
  hideHeader?: boolean;
  onSuccess: () => void;
  forwardTo?: string | null;
};
declare const bootstrap: any;

const LoginForm = ({ hideHeader = false, onSuccess, forwardTo = '' }: TProps) => {
  const { t } = useTranslation();
  const fetcher = useFetcher();
  const lang = useLanguageParam();
  const [isPasswordType, setIsPasswordType] = useState(true);
  const clientData = useUser();
  const navigate = useNavigate();
  const { search } = useLocation();

  //@ts-expect-error   TODO: solve
  const passwordError = fetcher?.data?.fieldErrors?.password || null;
  //@ts-expect-error   TODO: solve
  const emailError = fetcher?.data?.fieldErrors?.email || null;
  //@ts-expect-error   TODO: solve
  const formErrorMessage = fetcher?.data?.formError?.message;

  useEffect(() => {
    if (clientData) {
      const tokenValid = clientData ? checkTokenValidity(clientData) : false;
      if (tokenValid) onSuccess();
    }
  }, [clientData, onSuccess]);

  const handleSignupRedirect = (mixpanelEvent: string, url: string) => {
    mixpanelTrack(mixpanelEvent);
    bootstrap.Modal.getOrCreateInstance('#signInModal')?.hide();
    navigate(url);
  };

  return (
    <div className="needs-validation">
      {!hideHeader && <div className="fo-display-5 -medium text-center mb-6">{t('forms.logInForm.subtitle')}</div>}
      <fetcher.Form
        action={`/login${search}`}
        method="POST"
        autoComplete="off"
      >
        <div className="form-floating">
          <input
            className={`form-control ${emailError || (!!formErrorMessage && !emailError) ? 'is-invalid' : ''}`}
            id="emailInput"
            type="text"
            name="email"
            placeholder={t('user.email.placeholder')}
            autoComplete="email"
          />
          <label htmlFor="emailInput">{t('user.email.label')}</label>
        </div>
        {(emailError || (!!formErrorMessage && !emailError)) && (
          <div className="mt-1 color-error-500">
            {t(formErrorMessage)}
            {t(emailError)}
          </div>
        )}

        <div className="position-relative form-floating mt-6">
          <input
            type={isPasswordType ? 'password' : 'text'}
            className={`form-control ${passwordError || (!!formErrorMessage && !passwordError) ? 'is-invalid' : ''}`}
            id={`password-inp`}
            placeholder={t('user.password.label')}
            autoComplete={`current-password`}
            name="password"
          />
          <label htmlFor={`password-inp`}>{t('user.password.label')}</label>
          <button
            className={`position-absolute ${
              passwordError || (!!formErrorMessage && !passwordError) ? 'me-4' : ''
            } end-0 top-50 translate-middle-y btn btn-link`}
            type="button"
            onClick={() => setIsPasswordType(!isPasswordType)}
          >
            <SVGIcon iconType={isPasswordType ? 'eye-open' : 'eye-closed'} />
          </button>
        </div>
        {(passwordError || (!!formErrorMessage && !passwordError)) && (
          <div className="mt-1 color-error-500">
            {t(formErrorMessage)}
            {t(passwordError)}
          </div>
        )}

        <Button
          type="submit"
          variant="accent"
          size="lg"
          extraClasses="w-100 mt-6"
          onClick={() => {
            fetcher.data = {};
            mixpanelTrack('0.sign_in_true');
          }}
        >
          {' '}
          <span>{t('navbar.user.visitor.signIn')}</span>
        </Button>
      </fetcher.Form>

      <div className="text-center mt-6">
        <LinkText
          variant="accent"
          obfuscated={true}
          to={`/${lang}/reset-password${forwardTo ? `?forwardTo=${forwardTo}` : ''}`}
          reloadDocument={true}
        >
          {t('global.links.forgotPassword')}
        </LinkText>
      </div>
      <div className="d-flex flex-column justify-content-center mt-6 pt-6 fo-border-top-sm border-grey-200">
        <span className="text-1 -regular text-center mb-4">{t('forms.logInForm.noAccount')}</span>

        <Button
          variant="primaryOutline"
          size="lg"
          role="link"
          extraClasses="w-100 mb-4"
          onClick={() => handleSignupRedirect('B.find_an_office', `/${lang}/signup/guest`)}
        >
          <span>{t('global.links.findAnOffice')}</span>
        </Button>
        <div className="d-flex align-self-stretch align-items-center mb-4">
          <div className="flex-grow-1 fo-border-bottom-sm border-grey-200"></div>
          <span className="text-1 -regular color-grey-500 mx-3">{t('forms.logInForm.or')}</span>
          <div className="flex-grow-1 fo-border-bottom-sm border-grey-200"></div>
        </div>

        <Button
          variant="primaryOutline"
          size="lg"
          extraClasses="w-100"
          onClick={() => handleSignupRedirect('A.publish_an_office', `/${lang}/signup/host`)}
        >
          <span>{t('global.links.publishAnAdd')}</span>
        </Button>
      </div>
    </div>
  );
};

export default LoginForm;
